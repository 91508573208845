import About from "./About";

import React from "react";
import Map from "../map/Map";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Stat from "../charts/Stat";
import Area from "../charts/AreaEK";
import AreaTT from "../charts/AreaTT";
import TreeMap from "../charts/TreeMap";
import AreaAuc from "../charts/AreaAuc";
import AreaComp from "../charts/AreaComp";
import AreaEShop from "../charts/AreaEShop";
import StackedBars from "../charts/StackedBars";
import StackedBars_23 from "../charts/StackedBars_23";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";

function Home() {
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <About/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Stat/>
                    </Col>
                </Row>
                <Row className='my-5'>
                    <Col>
                        <Accordion className='shadow-sm rounded-5'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>
                                    Savdo turlari bo'yicha hisobot
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Area/>
                                    <AreaEShop/>
                                    <AreaAuc/>
                                    <AreaTT/>
                                    <AreaComp/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                    <Col className=' p-3 rounded-3 border border-light'>
                        <StackedBars/>

                        <StackedBars_23/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/*<Map/>*/}
                    </Col>
                </Row>
                <Row className='my-5' style={{margin: "0px"}}>
                    <Col>
                        <TreeMap/>
                        <hr/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;
