import React from "react";
import { MdOutlinePictureAsPdf } from "react-icons/md";

function Comm() {
  return (
    <>
      <div className="container shadow-sm px-5 py-4 rounded-5">
        <h2 className="m-5 border-bottom text-center">
          Davlat xaridlari sohasidagi shikoyatlarni ko‘rib chiqish bo‘yicha
          komissiya hisobotlari
        </h2>
        <ul className="list-group  list-group-flush">
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />{" "}
            <a
              target="_blank"
              href="/docs/info/komissiya_hisoboti_2022_yil.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sohasidagi shikoyatlarni ko‘rib chiqish bo‘yicha
              komissiya tomonidan 2022 yilda ko‘rib chiqilgan murojaatlar va
              ularnnng natijalari to‘g‘risida ma'lumot
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/komissiya_hisoboti_2023_yil.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sohasidagi shikoyatlarni ko‘rib chiqish bo‘yicha
              komissiya tomonidan 2023 yil 1-chorakda ko‘rib chiqilgan
              murojaatlar va ularnnng natijalari to‘g‘risida ma'lumot
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/komissiya_hisoboti_2024_6_oy.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sohasidagi shikoyatlarni koʻrib chiqish boʻyicha
              komissiya tomonidan 2024-yil 2 chorakda koʻrib chiqilgan
              murojaatlar va ularnnng natijalari toʻgʻrisida ma'lumot
            </a>
          </li>
        </ul>
      </div>
      <div className="container shadow-sm px-5 py-4 rounded-5">
        <h2 className="m-5 border-bottom text-center">
          Davlat xaridlari sohasidagi shikoyatlarni ko‘rib chiqish bo‘yicha
          komissiya majlisi bayonlari
        </h2>
        <ul className="list-group  list-group-flush">
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00018-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00018-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00019-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00019-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00020-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00020-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00021-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00021-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00022-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00022-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />

            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00023-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00023-son bayoni
            </a>
          </li>
          <li className="list-group-item">

            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00024-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00024-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00025-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00025-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00026-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00026-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00027-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00026-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00028-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00028-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00029-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00029-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00030-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00030-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00031-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00031-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00032-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00032-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00033-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00033-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00034-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00034-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00041-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00041-son bayoni            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00042-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00042-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00044-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00044-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00045-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00045-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00046-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining P00046-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 1-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 1-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 2-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 2-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 3-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 3-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 4-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 4-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 5-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 5-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 6-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 6-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 7-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 7-son bayoni
            </a>
          </li>
          <li className="list-group-item">
            <MdOutlinePictureAsPdf
              className="mb-1"
              style={{ fontSize: 24, color: "#f40f02" }}
            />
            <a
              target="_blank"
              href="/docs/info/Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 8-son bayoni.pdf"
              className="text-decoration-none"
            >
              Davlat xaridlari sihasidagi shikoyatlarni ko'rib chiqish bo'yicha komissiya yig'ilisining 8-son bayoni
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Comm;
